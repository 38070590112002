@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

/* VARIABLES FOR GLOBAL STYLES */
:root {
  --default-font-size: 62.5%;
  --default-font-family: "Roboto", sans-serif;
}

/* GLOBAL STYLES */
* {
  box-sizing: border-box;
}

html {
  font-size: var(--default-font-size);
}

body {
  font-family: var(--default-font-family);
  font-size: 1.6rem;
  letter-spacing: 0.03rem;
}

a {
  text-decoration: none;
  color: #333;
}
